// When true, use live data but the localhost server.
const devLocallyWithLiveData = false;

const isDev = devLocallyWithLiveData
  ? false
  : process.env.NODE_ENV !== "production";

export const SERVER =
  isDev || devLocallyWithLiveData
    ? "https://www.localhost:1110/v0.1/"
    : "https://mealplan-platform-generator.herokuapp.com/v0.1/";

export const DEV = isDev;

export const ELASTIC_SEARCH = {
  api_key: "THV5Y3dvMEIweWg4d2d4UW5ZdU46cTFVQ1FkdlVSbmlCWG1OeGhkbUgyQQ==",
  url: "https://68e4d31899914e5f82250eeefa8d9081.us-central1.gcp.cloud.es.io:443"
};
